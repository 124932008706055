import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Link } from 'gatsby'

const EnergisPage = () => (
  <Layout>
    <SEO
      title="Energis"
      description="Read the case study of Watt für dich, an app development project for Energis."
    />
    <section>
      <hgroup>
        <h6>2015-2016</h6>
        <h2>Energis - Watt für dich</h2>
      </hgroup>
      <div className="project-header-image">
        <Image filename="energis-home" />
        <div className="text-center mt-40">
          <a
            href="https://itunes.apple.com/us/app/watt-für-dich/id1114464751?l=de&ls=1&mt=8"
            className="btn"
          >
            Visit App Store
          </a>
        </div>
      </div>

      <div className="section-columns">
        <div>
          <h3>Overview</h3>
          <p>
            Energis is one of the major energy companies in Saarland, Germany.
            They planned a big marketing campaign for the summer of 2016 called
            „Watt für dich“, where people could earn watt points through sport
            activities. For the campaign they wanted to create a mobile app for
            iOS and Android to use the phones step calculation as basis for the
            watt point generation.
          </p>
          <p>
            Users were able collect points daily and redeem them in the campaign
            shop. The points worked as currency, so you were only able to buy
            something using your collected points.
          </p>
          <p>
            The idea behind was to use physical energy and convert it to digital
            watts. Existing Energis customers even got extra discounts and
            access to special offers. Challenges with friends and other users
            during the campaign should motivate to do sports and earn more
            points.
          </p>
        </div>
        <div className="pt-48">
          <p>
            I was responsible for the whole design process of the iOS and
            Android app and also worked together with the development team to
            provide all needed assets for each platform. The design process was
            filled with several feedback loops with all stakeholders from
            Energis and also from the external marketing agency, which planned
            the overall campaign.
          </p>
          <p>
            Besides the applications I also created the design for the campaign
            online shop.
          </p>
        </div>
      </div>

      <div className="section-columns">
        <div>
          <h3>Challenge</h3>
          <p>
            From start to end of the project, the campaign was still in planning
            mode, so features changed, got removed or added regularly.
            Furthermore the marketing agency already created the branding for
            the campaign, which didn’t really fit for digital products.
          </p>
          <p>
            The visualisation of the earned watt points was crucial for the
            application, as it was the main screen. Since Energis is an energy
            company, I had to come up with a visualisation of electric energy,
            which represents the earned points through physical energy.
          </p>
          <p>
            Due to the fact, that the application was build as native apps, the
            design needed to be optimised for iOS and Android.
          </p>
        </div>
        <div>
          <h3>Approach</h3>
          <p>
            Building an app with a step counter wasn’t something new. So I
            started to analyse other applications, which were successful in the
            market.
          </p>
          <p>
            Before moving into the detailed application design I started to
            experiment with different visualisations of the electric energy. I
            did some research and came up with various ideas, some were quick
            shots and some already detailed. This helped to narrow down the best
            solutions and iterate until all stakeholders were satisfied.
          </p>
        </div>
      </div>
      <div className="section-columns col-4">
        <div>
          <Image filename="tracking-A" width="100%" />
        </div>
        <div>
          <Image filename="tracking-B" width="100%" />
        </div>
        <div>
          <Image filename="tracking-C" width="100%" />
        </div>
        <div>
          <Image filename="tracking-D" width="100%" />
        </div>
        <div>
          <Image filename="tracking-E" width="100%" />
        </div>
        <div>
          <Image filename="tracking-F" width="100%" />
        </div>
        <div>
          <Image filename="tracking-G" width="100%" />
        </div>
        <div>
          <Image filename="tracking-H" width="100%" />
        </div>
        <div>
          <Image filename="tracking-I" width="100%" />
        </div>
        <div>
          <Image filename="tracking-J" width="100%" />
        </div>
        <div>
          <Image filename="tracking-K" width="100%" />
        </div>
        <div>
          <Image filename="tracking-L" width="100%" />
        </div>
      </div>
      <div className="section-columns">
        <div>
          <h3>Implementation</h3>
          <p>
            At the beginning of the process it was necessary to create the user
            flow chart to get an overview of the application. I also split-up
            the complexity of the app in smaller and manageable sections. This
            helped to do faster feedback loops with the stakeholders, as we
            moved forward from section to section separately. A version control
            system for all files also increased transparency for the team.
          </p>
          <p>
            As soon as the main visualisation was done, I began to create the
            full application design. It was also crucial to create a modular
            design system from the beginning to easily change screens later in
            the process and change styles based on the OS. For the final
            application I’ve created 167 screens for iOS and the same amount for
            Android.
          </p>
        </div>
        <div className="pt-48">
          <p>
            To have a consistent style across all digital products for the
            campaign, I also used the design styles of the application for the
            online shop.
          </p>
          <p>
            During the process I’ve created several clickable prototypes and
            also worked with the development team to provide all assets for both
            applications.
          </p>
        </div>
      </div>
      <div className="section-columns col-4">
        <div>
          <Image filename="energis-1" width="100%" />
          <p>
            Profile view with weekly activity. Users were also able to collect
            badges for each finished challenges to stay motivated.{' '}
          </p>
        </div>
        <div>
          <Image filename="energis-2" width="100%" />
          <p>
            During the campaign there were several community challenges and also
            solo challenges.
          </p>
        </div>
        <div>
          <Image filename="energis-3" width="100%" />
          <p>
            Community challenges offered a highscore list to compete with others
            and also earn extra points.
          </p>
        </div>
        <div>
          <Image filename="energis-4" width="100%" />
          <p>
            In the integrated shop users were able to redeem earned points as
            vouchers to get discount on several products.
          </p>
        </div>
      </div>
      <div className="section-columns">
        <div>
          <Image filename="shop-home" width="100%" />
        </div>
        <div>
          <Image filename="shop" width="100%" />
        </div>
      </div>
    </section>
    <div className="cta-section">
      <section>
        <h2>Do you have a product idea?</h2>
        <p>
          I've helped more than <strong>60+ products</strong> to realise their
          vision in a market ready product. <br /> I would be happy to help you,
          developing your digital product from scratch and get it live.
        </p>
        <a href="https://calendly.com/dannygiebe/connect" className="btn">
          Let's have a chat
        </a>
      </section>
    </div>
    <section>
      <div className="similar">
        <div>
          <Link to="/project/goretex/">Read Gore-Tex study</Link>
        </div>
        <div className="text-center">
          <Link to="/">Go to homepage</Link>
        </div>
        <div className="text-right">
          <Link to="/project/triphunt/">Read Triphunt study</Link>
        </div>
      </div>
    </section>
  </Layout>
)
export default EnergisPage
